import { memo, useCallback } from "react";
import { toLocal } from "../../helpers/methods";

function ReportComponent(props) {

  const reportDataItems = useCallback(() => {
    if(props.reportData && props.reportData[0]) {
      let classSalary = "align-right";
      if (Math.abs(props.reportData[0].total_salaries - props.statData[0].total_salary) > 2 ) {
        classSalary = "align-right-red";
      }
      let classWithdrawals = "align-right";
      if (Math.abs(props.reportData[0].total_incomes -  props.statData[0].crypto - props.reportData[0].total_withdrawals ) > props.reportData[0].balance_history ) {
        classWithdrawals = "align-right-red";
      }
      let classCash = "align-right";
      if (props.reportData[0].total_expenses != props.statData[0].cash) {
        classCash = "align-right-red";
      }
      return props.reportData.map((row, index) => {
        return (
          <tr key={`report-row-${index + 1}`}>
            <td className="align-right">{toLocal(row.total_incomes)}</td>
            <td className={classWithdrawals}>{toLocal(row.total_withdrawals)}</td>
            <td className={classSalary}>{toLocal(row.total_salaries)}</td>
            <td className={classCash}>{toLocal(row.total_expenses)}</td>
            <td className={row.accounting_income > 0 ? "align-right bold-row" : "align-right-red bold-row"}>{toLocal(row.accounting_income)}</td>
            <td className="blue-align-right">{toLocal(row.income_prc)}</td>
            <td className="align-right">{toLocal(row.balance_history)}</td>
          </tr>
        )
      })
    }
  }, [props.reportData, props.statData]);

  const statDataItems = useCallback(() => {
    if(props.statData) {
      return props.statData.map((row,index) => {
        return (
          <tr key={`stat-row-${index + 1}`}>
            <td className="align-right">{toLocal(row.salary)}</td>
            <td className="align-right">{toLocal(row.closer)}</td>
            <td className="align-right">{toLocal(row.total_salary)}</td>
            <td className="align-right">{toLocal(row.cash)}</td>
            <td className="align-right">{toLocal(row.crypto)}</td>
          </tr>
        )
      })
    }
  }, [props.statData]);

  return (
    <div className="card-body overflow-auto">
      <table className="table table-hover">
        <thead id="tableHeader">
        <tr>
          <th>Приход</th>
          <th>Выплата</th>
          <th>Зарплата</th>
          <th>Расходы, нал</th>
          <th>Прибыль</th>
          <th>%%</th>
          <th>Остаток</th>
          </tr>
        </thead>
        <tbody id="content">
         {reportDataItems()}
        </tbody>
      </table>
      <table className="table table-hover">
        <thead id="tableHeader">
          <tr key={0}>
            <th className="onefifth">Зарплата, менеджеры</th>
            <th className="onefifth">Зарплата, клозеры</th>
            <th className="onefifth">Зарплата, всего</th>
            <th className="onefifth">Расходы, нал</th>
            <th className="onefifth">Расходы, крипто</th>
          </tr>
        </thead>
        <tbody id="content">
         {statDataItems()}
        </tbody>
      </table>
    </div>
  );
}

export default memo(ReportComponent);
