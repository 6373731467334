import axiosInstance from "../axios";

class TransactionsService {

  getTransactions = async (dateFrom, dateTo) => {
    const response = await axiosInstance(
            `/transactions/`,
            {
              params: {
                created__gte: dateFrom,
                created__lte: dateTo,
                page: 1,
            }
          }
    );
    return { status: true, data: response.data };
  };
}

export default TransactionsService;
