import { memo, useCallback } from "react";
import moment from "moment";
import { toLocal } from "../../helpers/methods";

function TransactionsTableComponent(props) {
    // function totalDataItems(){
    //   if (typeof props.statData !== undefined) {
    //     if (props.statData[0]) {
    //       return (
    //       <tr className="bold-row">
    //         <td>&nbsp;</td>
    //         <td>Всего:</td>
    //         <td>&nbsp;</td>
    //         <td className="align-right">{toLocal(props.statData[0].amount)}</td>
    //         <td>&nbsp;</td>
    //         <td className="align-right">{toLocal(props.statData[0].salary)}</td>
    //         <td className="align-right">{toLocal(props.statData[0].closer)}</td>
    //       </tr>
    //       )
    //     }
    //   }
    // }

    const tableDataItems = useCallback(() => {
        if (!props.transactionsData) return null;

        return props.transactionsData.map((row, index) => (
            <tr key={`transaction-table-row-${index}`}>
                <td>{index + 1}</td>
                <td>{moment(row.created).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td className="align-right">{row.from_wallet_name}</td>
                <td className="align-right">{row.description}</td>
                <td className="align-right">{toLocal(row.friendly_amount)}</td>
            </tr>
        ));
    }, [props.transactionsData]);

    return (
        <div className="card-body">
            <table className="table table-hover">
                <thead id="tableHeader">
                    <tr>
                        <th className="id-cell">ID</th>
                        <th className="column-min-100">Дата</th>
                        <th>Офис</th>
                        <th>Описание</th>
                        <th>Сумма</th>
                    </tr>
                </thead>
                <tbody id="content">
                    {/*{totalDataItems()}*/}
                    {tableDataItems()}
                </tbody>
                <tfoot>
                    {/*{totalDataItems()}*/}
                </tfoot>
            </table>
        </div>
    );
}

export default memo(TransactionsTableComponent);
