import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";
import { ContextProvider } from "./context";

// pages
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import OfficePage from "./pages/OfficePage";
import TransactionsPage from "./pages/TransactionsPage";

// styles
import "./styles/App.scss";

function App() {
    const cookies = new Cookies();

    const [user, setUser] = useState(
        cookies.get("user") ? cookies.get("user") : null
    );

    cookies.addChangeListener(function (name, value) {
        console.log(name, value);
        if (name === "user") {
          setUser(value);
        }
    });

    return (
      <ContextProvider>
          <Router>
          {user ? (
                  <React.Fragment>
                      <div className="container-fluid">
                          <Routes>
                              <Route path="/" element={<MainPage />} />
                              <Route path="/office/:officeId" element={<OfficePage />} />
                              <Route path="/transactions/" element={<TransactionsPage />} />
                          </Routes>
                      </div>
                  </React.Fragment>
              ) : (
                  <LoginPage />
              )}
          </Router>
      </ContextProvider>
    );
}

export default App;
