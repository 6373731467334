import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from "moment";
import {useMyContext} from "../context";

// helpers & services
import WeeksList from "../helpers/WeekList";
import {WEEKS_START} from "../helpers/constants";
import TransactionsService from "../services/TransactionsService";

// components
import WeekSelectorComponent from "../components/WeekSelectorComponent";
import TransactionsTableComponent from "../components/TransactionsTableComponent";
import HeaderComponent from "../components/HeaderComponent";

function TransactionsPage() {
    const weeksList = new WeeksList(WEEKS_START);
    const transactionsService = new TransactionsService();

    const {selectedWeekIndex} = useMyContext();
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [transactionsData, setTransactionsData] = useState([]);

    useEffect(() => {
        onWeekChange(selectedWeek);
    }, [selectedWeek]);

    useEffect(() => {
        const newWeekPeriod = weeksList.getByIndex(selectedWeekIndex);
        const newDate = new Date(newWeekPeriod?.dateFrom);
        newDate.setDate(newDate.getDate() + 6);
        newWeekPeriod.dateTo = moment(newDate).format("YYYY-MM-DD");
        setSelectedWeek(newWeekPeriod);
    }, [selectedWeekIndex]);

    const getTransactionsData = useCallback(async(dateFrom, dateTo) => {
        if (selectedWeek) {
            const transactions = await transactionsService.getTransactions(dateFrom, dateTo);
            setTransactionsData(transactions.data.results);
        }
    }, [selectedWeek, transactionsService]);

    const onWeekChange = useCallback(async(selectedWeek) => {
        console.log(selectedWeek);
        if (selectedWeek) {
            const newTo = new Date(selectedWeek.dateFrom);
            newTo.setDate(newTo.getDate() + 4);

            await getTransactionsData(selectedWeek.dateFrom, moment(newTo).format("YYYY-MM-DD"));

            const newFrom = new Date(selectedWeek.dateFrom);
            newFrom.setDate(newFrom.getDate() + 5);
        }
    }, [getTransactionsData]);

    // Memoize the data before passing it to MainTabs to avoid recalculating each render
    const memoizedTransactionsData = useMemo(() => transactionsData, [transactionsData]);

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between p-3">
                    <HeaderComponent/>
                    {/*<div className="name-div bold-row">&nbsp;</div>*/}
                    <div className="selector-div">
                        <WeekSelectorComponent onWeekChange={onWeekChange}/>
                    </div>
                </div>
                <TransactionsTableComponent
                    key={transactionsData}
                    transactionsData={memoizedTransactionsData}
                />
            </div>
        </React.Fragment>
    )
}

export default TransactionsPage;
