import { WEEKS_START } from "../../helpers/constants";
import WeeksList from "../../helpers/WeekList";
import { useMyContext } from "../../context";
import { memo } from "react";

function WeekSelectorComponent({ children }) {
    const weeksList = new WeeksList(WEEKS_START);
    const { selectedWeekIndex, setSelectedWeekIndex } = useMyContext();

    function onWeekChangeHandler(e) {
        setSelectedWeekIndex(e.target.value);
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="d-flex align-items-center flex-wrap justify-content-end form-group">
                <label
                    className="mx-2"
                    style={{
                      whiteSpace: "nowrap",
                      height: "fit-content",
                    }}
                >
                    Ведомость за
                </label>
                <select
                    className="form-select"
                    value={selectedWeekIndex}
                    onChange={onWeekChangeHandler}
                >
                    {weeksList.getWeeksList().map((week, index) => (
                        <option key={index} value={index}>
                            {week.dateFrom} - {week.dateTo}
                        </option>
                    ))}
                </select>
            </div>
            {children}
        </div>
    );
}

export default memo(WeekSelectorComponent);
