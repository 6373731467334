import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useMyContext } from "../context";
import moment from "moment";

// helpers & services
import { REPORTING_DAYS_TYPES, WEEKS_START } from "../helpers/constants";
import WeeksList from "../helpers/WeekList";
import NewService from "../services/NewService";
// import AccountingService from "../services/AccountingService";

// components
import WeekSelectorComponent from "../components/WeekSelectorComponent";
import HeaderComponent from "../components/HeaderComponent";
import MainTabs from "../components/MainTabs";

function MainPage() {
  const newService = new NewService();
  // const accountingService = new AccountingService();
  const weeksList = new WeeksList(WEEKS_START);

  const { selectedWeekIndex } = useMyContext();

  const [selectedWeek, setSelectedWeek] = useState(null);

  const [newWeekData, setWeekData] = useState([]);
  const [newWeekTotals, setWeekTotals] = useState([]);
  const [newWeekEndData, setWeekEndData] = useState([]);
  const [newWeekEndTotals, setWeekEndTotals] = useState([]);

  const [currentReportingDaysTypes, setCurrentReportingDaysTypes] = useState(
    REPORTING_DAYS_TYPES?.weekdays
  );

  useEffect(() => {
    onWeekChange(selectedWeek);
  }, [selectedWeek]);

  useEffect(() => { 
    const newWeekPeriod = weeksList.getByIndex(selectedWeekIndex);
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekdays) {
      const newDate = new Date(newWeekPeriod?.dateFrom);
      newDate.setDate(newDate.getDate() + 6);
      newWeekPeriod.dateTo = moment(newDate).format("YYYY-MM-DD");
    }
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekends) {
      const newDate = new Date(newWeekPeriod?.dateTo);
      newDate.setDate(newDate.getDate() - 1);
      newWeekPeriod.dateFrom = moment(newDate).format("YYYY-MM-DD");
    }
    setSelectedWeek(newWeekPeriod);
  }, [currentReportingDaysTypes, selectedWeekIndex]);

  const getDataW = useCallback(async (dateFrom, dateTo) => {
    const weekData = await newService.getOfficesList(
        dateFrom,
        dateTo,
        false
    );
    setWeekData(weekData.data.results);
    setWeekTotals(weekData.data.total_statistics);
  }, [newService]);

  const getDataWe = useCallback(async (dateFrom, dateTo) => {
    const weekEndData = await newService.getOfficesList(
        dateFrom,
        dateTo,
        true
    );
    setWeekEndData(weekEndData.data.results);
    setWeekEndTotals(weekEndData.data.total_statistics);
  }, []);

  const onWeekChange = useCallback((selectedWeek) => {
    if (selectedWeek) {
      const newTo = new Date(selectedWeek.dateFrom);
      newTo.setDate(newTo.getDate() + 4);

      getDataW(selectedWeek.dateFrom, moment(newTo).format("YYYY-MM-DD"));

      const newFrom = new Date(selectedWeek.dateFrom);
      newFrom.setDate(newFrom.getDate() + 5);

      getDataWe(moment(newFrom).format("YYYY-MM-DD"), selectedWeek.dateTo);
    }
  }, [getDataW, getDataWe]);

  // Memoize the data before passing it to MainTabs to avoid recalculating each render
  const memoizedWeekData = useMemo(() => newWeekData, [newWeekData]);
  const memoizedWeekTotals = useMemo(() => newWeekTotals, [newWeekTotals]);
  const memoizedWeekEndData = useMemo(() => newWeekEndData, [newWeekEndData]);
  const memoizedWeekEndTotals = useMemo(() => newWeekEndTotals, [newWeekEndTotals]);

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between p-3">
       <HeaderComponent/>
        {/*<div className="name-div bold-row">&nbsp;</div>*/}
        <div className="selector-div">
          <WeekSelectorComponent onWeekChange={onWeekChange}/>
        </div>
      </div>
      <MainTabs
          newWeekData={memoizedWeekData}
          newWeekTotals={memoizedWeekTotals}
          newWeekEndData={memoizedWeekEndData}
          newWeekEndTotals={memoizedWeekEndTotals}
      />
    </div>
  );
}
export default MainPage;
