import { Link } from "react-router-dom";
import { memo } from "react";

function HeaderComponent() {
	return (
		<div className="home-link">
			<Link to="/" className="pb-1">
				Список офисов
			</Link>
			<Link to="/transactions" className="pb-1">
				Транзакции
			</Link>
		</div>
	)
}

export default memo(HeaderComponent);
