import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

function NewComponent(props) {
    let counter = 0;
    let reported = 0;

    function to_local(value) {
        let output = " "
        if (typeof value != 'undefined') {
          output = value?.toLocaleString("ru-RU", { maximumFractionDigits: 0 })
        }
        return output;
    }

    const totalDataItems = useCallback(() => {
        if (typeof props.tableTotals !== 'undefined') {
            if (props.tableTotals) {
                return (
                    <tr className="bold-row">
                        <td>&nbsp;</td>
                        <td>Всего:</td>
                        <td className="align-right">{to_local(props.tableTotals.total_incomes)}</td>
                        <td className="align-right">{to_local(props.tableTotals.total_withdrawals)}</td>
                        <td className="align-right">{to_local(props.tableTotals.total_salaries)}</td>
                        <td className="align-right">{to_local(props.tableTotals.total_expenses)}</td>
                        <td className="align-right">{to_local(props.tableTotals.total_expenses_plus_salaries)}</td>
                        <td className={ props.tableTotals.accounting_income > 0 ? "align-right" : "align-right-red" }>{to_local(props.tableTotals.accounting_income)}</td>
                        <td className="align-right">{to_local(props.tableTotals.income_prc)}</td>
                        <td className="align-right">{to_local(props.tableTotals.balance_history/1)}</td>
                    </tr>
                )
            }
        }
    }, [props.tableTotals]);

    const tableDataItems = useCallback(() => {
        counter = 0;
        reported = 0;
        let classname = "";

        return props.tableData.map((row, index) => {
            counter = counter + 1;

            if (row.total_incomes == 0) {
                classname = "table-danger";
                reported = reported + 1;
            } else {
                if (row.accounting_income > 0) {
                    classname = "table-success";
                }
                else {
                    classname ="";
                }
            }

            return (
                <tr className={classname} key={index}>
                    <td>{index + 1}</td>
                    <td>
                        <Link
                            to={`/office/${row.id}`}
                        >
                            {row.name}
                        </Link>
                    </td>
                    <td className="align-right">{to_local(row.total_incomes)}</td>
                    <td className="align-right">{to_local(row.total_withdrawals)}</td>
                    <td className="align-right">{to_local(row.total_salaries)}</td>
                    <td className="align-right">{to_local(row.total_expenses)}</td>
                    <td className="align-right">{to_local(row.total_expenses_plus_salaries)}</td>
                    <td className={ row.accounting_income > 0 ? "align-right" : "align-right-red" }>{to_local(row.accounting_income)}</td>
                    <td className="align-right">{to_local(row.income_prc)}</td>
                    <td className="align-right">{to_local(row.balance_history/1)}</td>
                </tr>
            )
        })
    }, [props.tableData]);

    return (
        <div className="card-body overflow-auto">
            <table className="table table-hover">
                <thead id="tableHeader">
                    <tr>
                        <th>#</th>
                        <th>Офис</th>
                        <th>Приход</th>
                        <th>Вывод</th>
                        <th>Зарплата</th>
                        <th>Расход нал</th>
                        <th>Расход + ЗП</th>
                        <th>Прибыль</th>
                        <th>%%</th>
                        <th>Остаток</th>
                    </tr>
                </thead>
                <tbody id="content">
                    {totalDataItems()}
                    {tableDataItems()}
                </tbody>
                <tfoot>
                    {totalDataItems()}
                </tfoot>
            </table>
        </div>
    );
}

export default memo(NewComponent);
